import React, { useState, useEffect } from "react";
import "./Slider.css";
import BtnSlider from "./BtnSlider";
export default function Slider(props) {

    let playVideo = () => {
        this.refs.vidRef.play();
      }
    

    let flag = false;
  const [slideIndex, setSlideIndex] = useState(1);
  const nextSlide = () => {
    if (slideIndex !== dataSlider.length) {
      setSlideIndex(slideIndex + 1);
    } else if (slideIndex === dataSlider.length) {
      setSlideIndex(1);
    }flag = false;
  };

  const prevSlide = () => {
    if (slideIndex !== 1) {
      setSlideIndex(slideIndex - 1);
    } else if (slideIndex === 1) {
      setSlideIndex(dataSlider.length);
    }flag = false;
  };

  const moveDot = (index) => {
    setSlideIndex(index);
  };
  let content
  if (props.auto) {
    if (props.auto == "1") {
       content = props.Content;
    }
    else{
        content = JSON.parse(props.Content);

    }
  } else {
    content = JSON.parse(props.Content);
 }
  let dataSlider = [];
  if (content.Images) {
    for (let i = 0; i < content.Images.length; i++) {
      dataSlider.push(content.Images[i]);
    }
  }
  if (content.Videos) {
    for (let i = 0; i < content.Videos.length; i++) {
      dataSlider.push(content.Videos[i]);
    }
  }

  useEffect(() => {
    if (props.auto) {
      if (props.auto == "1") {
        const interval = setInterval(() => {
          nextSlide();
        }, 5000); // Call nextSlide every 5 seconds

        return () => clearInterval(interval); // Cleanup on unmount
      }
    }
  }, [nextSlide]);
  return (
    <div className={"container-slider "}>
      {dataSlider.map((obj, index) => {
        if (slideIndex === index + 1) {
          if (slideIndex >= content.Images.length + 1) {
            flag = false;
            flag = true;
            
            console.log("here")
            return (
              <div
                key={obj.id}
                className={
                  slideIndex === index + 1 ? "slide active-anim" : "slide"
                }
                style={(props.auto === "2")?
                    {display: (slideIndex === index + 1 ?  "inline" :"none")
                        
                    }:({})
                  }
              >
                {
                            <video 
                            autoPlay={props.auto === "1" || props.auto === "2"} 
                            loop={props.auto === "1" || props.auto === "2"} 
                            muted={props.auto === "1" || props.auto === "2"} 
                            controls={(!(props.auto === "1") || (props.auto === "2" && flag))}
                          >
                            <source src={dataSlider[index]} type="video/mp4" />
                            Your browser does not support the video tag.
                          </video>
            }
              </div>
            );
          } else {
            flag = false;
            return (
              <div
                key={obj.id}
                className={
                  slideIndex === index + 1 ? "slide active-anim" : "slide"
                }
              >
                <img src={dataSlider[index]} />
              </div>
            );
          }
        } else {           flag=false;
            if (slideIndex >= content.Images.length + 1) {
 
                
                return (
                  <div
                    key={obj.id}
                    className={
                      slideIndex === index + 1 ? "slide active-anim" : "slide"
                    }
                    style={(props.auto === "2")?
                        {display: (slideIndex === index + 1 ?  "inline" :"none")
                            
                        }:({})
                      }
                  >
                    {
                            <video 
                             src={dataSlider[index]} type="video/mp4"
                          >
                              </video>
                }
                  </div>
                );
              }else{


          return (
            <div
              key={obj.id}
              className={
                slideIndex === index + 1 ? "slide active-anim" : "slide"
              }
            >
              <img src={dataSlider[index]} />
            </div>
          );
        }              }
      })}
      <BtnSlider moveSlide={nextSlide} direction={"next"} />
      <BtnSlider moveSlide={prevSlide} direction={"prev"} />

      <div className="container-dots">
        {Array.from({ length: dataSlider.length }).map((item, index) => (
          <div
            onClick={() => moveDot(index + 1)}
            className={slideIndex === index + 1 ? "dot active" : "dot"}
          ></div>
        ))}
      </div>
    </div>
  );
}
