import "../App.css";
import Drums from "../JSON/Drums.json";
import NavBar from "./NavBar";
import Slider from "./Slider/Slider"
import Footer from "./Footer";

import React, { Component } from "react";
import styled, { keyframes } from "styled-components";
import { fadeInLeft } from "react-animations";

const FadeInLeft = styled.div`
  animation: ${({ animationTime }) => `${animationTime}s`}
    ${keyframes`${fadeInLeft}`};
`;

function App() {
  return (
    <div className="App">
      <NavBar />

      <main
        style={{ textAlign: "center", alignItems: "center" }}
        className="DrumsArticles"
      >
        {/*
        <FadeInLeft animationTime={1}>
          <article>
            {" "}
            <video controls style={{ width: "40%" }}>
              <source src={""} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <h1>
              {" "}
              Monsieur Bouhlel, Professeur de Physique Chimie et de Numérique et
              Science Informatiques (à traduire en anglais)
            </h1>
          </article>
        </FadeInLeft> */}

        <FadeInLeft animationTime={1.2}>
          <article>
            <Slider className="astronomy" Content={{"Images": ["../Content/Astronomy/Soirees/1.jpg", "../Content/Astronomy/Soirees/2.jpg", "../Content/Astronomy/Soirees/3.jpg"], "Videos":["../Content/Astronomy/Soirees/4.mp4"]}} auto="1"></Slider>
            <h1>Participation à l'animation des soirées d'observation astronomiques</h1>
          </article>
        </FadeInLeft>

        <FadeInLeft animationTime={1.4}>
          <article>
            <Slider className="astronomy" Content={{"Images": ["../Content/Astronomy/Douiret/1.JPG","../Content/Astronomy/Douiret/8.jpg", "../Content/Astronomy/Douiret/2.jpg", "../Content/Astronomy/Douiret/3.jpg", "../Content/Astronomy/Douiret/4.jpg", "../Content/Astronomy/Douiret/5.jpg", "../Content/Astronomy/Douiret/6.jpg", "../Content/Astronomy/Douiret/7.jpg"]}} auto="1"></Slider>
            <h1>Voyage à Douiret au sud de la Tunisie</h1>
          </article>
        </FadeInLeft>
        
      </main>
      <Footer />
    </div>
  );
}

export default App;
