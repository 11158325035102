import "../App.css";

import NavBar from "./NavBar";

import Footer from "./Footer";
import { useParams } from "react-router-dom";
import Slider from "./Slider/Slider"
import BlogContent from "../JSON/BlogContent.json";

function App() { 
  const { idRouter } = useParams()
  let obj
  Object.keys(BlogContent[2].data).forEach(x => obj = (BlogContent[2].data[x].id == idRouter ? BlogContent[2].data[x]: obj));
  console.log(idRouter)
  return (
    <div className="App">
    <NavBar/>
    <main>
    <section className="Page">
      <h1 style={{"marginLeft": "20px"}} id="HeadTitle"> {obj.name}: </h1>
      <p>{obj["text-content"]}</p>

    <Slider Content={obj["visual-content"]} orientation={obj.Orientation} auto="2"></Slider>

      {(obj.links != "")?(<a href={obj.links}> Open related link</a>):(<br/>)}
    </section>

  </main>

    <Footer />
    </div>
  );
}

export default App;