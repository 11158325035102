import BlogContent from "../JSON/BlogContent.json";

import { useNavigate } from "react-router-dom";
import { Link} from "react-router-dom"
function UpdateSmall(props) {
  const navigate =  useNavigate()

  return (
    <div className="UpdateSmall">
      <article>
        <img
          src={(BlogContent[2].data.find(item => item.id == props.id)["thumbnail"].indexOf("../") == -1)?("../" + BlogContent[2].data.find(item => item.id == props.id)["thumbnail"]):(BlogContent[2].data.find(item => item.id == props.id)["thumbnail"])}
          alt="Project Image"
          style={{ margin: "5%", maxWidth: "40%", maxHeight: "40%" }} 
        />
        <div>
          <h1>{BlogContent[2].data.find(item => item.id == props.id)["name"]}: </h1>
          <br />
          <img
            src={(BlogContent[2].data.find(item => item.id == props.id)["thumbnail"].indexOf("../") == -1)?("../" + BlogContent[2].data.find(item => item.id == props.id)["thumbnail"]):(BlogContent[2].data.find(item => item.id == props.id)["thumbnail"])}
            alt="Project Image"
            style={{ margin: "5%", " width": "45%" }}
            className="MImg"
          />
          <p>
            {BlogContent[2].data.find(item => item.id == props.id)["presentation-text"]}
          </p>
          <label>{BlogContent[2].data.find(item => item.id == props.id)["date"]}</label><br/>
          <Link to={"/pages/"+props.id}>Learn more</Link>
        </div>
      </article>
    </div>
  );
}

export default UpdateSmall;
