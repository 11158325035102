import "../App.css";

import NavBar from "./NavBar";

import Footer from "./Footer";
import Slider from "./Slider/Slider";
import UpdateSmall from "./UpdateSmall";

import BlogContent from "../JSON/BlogContent.json";
import { useState } from "react";
import React, { Component } from "react";
import styled, { keyframes } from "styled-components";
import { fadeInRight } from "react-animations";
import { useNavigate, useParams } from "react-router-dom";
const FadeInRight = styled.div`
  animation: ${({ animationTime }) => `${animationTime}s`}
    ${keyframes`${fadeInRight}`};
`;
function App() {
  const navigate = useNavigate();
  const [order, setOrder] = useState("latest");
  const { idRouter } = useParams();

  // Handle the change event
  const handleChange = (event) => {
    const value = event.target.value;
    setOrder(value);
    // Optionally trigger submission or some action here
    handleSubmit(value);
  };

  // Handle the form submission
  const handleSubmit = (value) => {
    // Your custom form submission logic here
    console.log("Form submitted with selected value:", value);
  };

  if (idRouter) {
    console.log(idRouter);
    if (idRouter == "1") {
      return (
        <>
          <NavBar />
          {/* 
          <main
            style={{ textAlign: "center", alignItems: "center" }}
            className="Prizes"
          >
            <FadeInRight animationTime={0.8}>
              <article>
                <Slider
                  Content={{
                    Images: [
                      "../Content/Astronomy/Douiret/1.jpg",
                      "../Content/Astronomy/Douiret/2.jpg",
                    ],
                  }}
                  auto="1"
                ></Slider>
              </article>
            </FadeInRight>
          </main>
          */}
          <div className="Updates">
            <section className="ProjectsList">
              <h1 id="1">Robotics</h1>

              {[...BlogContent[2].data].map((element, i) => {
                if (element["category"].indexOf("1") != -1) {
                  return (
                    <FadeInRight animationTime={1 + i * 0.2}>
                      <UpdateSmall User key={i} id={element.id} />
                    </FadeInRight>
                  );
                }
              })}
              <FadeInRight animationTime={1 + BlogContent[2].data.length * 0.2}>
                <div className="UpdateSmall">
                  <article>
                    <img
                      src={"../Content/Images/Earlier.png"}
                      alt="Project Image"
                      style={{
                        margin: "20px",
                        maxWidth: "40%",
                        maxHeight: "40%",
                      }}
                    />
                    <div>
                      <h1>Earlier projects and beginnings: </h1>
                      <br />
                      <img
                        src={"../Content/Images/Earlier.png"}
                        alt="Project Image"
                        style={{ margin: "20px", " width": "50%" }}
                        className="MImg"
                      />
                      <p>
                        This section of the website contains older projects and
                        how i got into programming and robotics.
                      </p>
                      <label> 2016-2020 </label>
                      <br />
                      <a
                        onClick={() => {
                          navigate("/Earlier");
                        }}
                      >
                        Learn more
                      </a>
                    </div>
                  </article>
                </div>
              </FadeInRight>
            </section>
            <Footer />
          </div>
        </>
      );
    }
    if (idRouter == "2") {
      return (
        <div>
          <NavBar />
          
                      <div className="Updates">
                      <section className="ProjectsList">
                        <h1 id="2">Computer Science</h1>
                        
              {[...BlogContent[2].data].map((element, i) => {
                if (element["category"].indexOf("2") != -1) {
                  return (
                    <FadeInRight animationTime={1 + i * 0.2}>
                      <UpdateSmall User key={i} id={element.id} />
                    </FadeInRight>
                  );
                }
              })}
            </section>
            <Footer />
          </div>
        </div>
      );
    }
  } else {
    return (
      <div className="Updates">
        <NavBar />
        <section className="ProjectsList">
          <h1>Achievements: </h1>

          <form onSubmit={(e) => e.preventDefault()}>
            <h2>Order by:{"  "}</h2>
            <select value={order} onChange={handleChange}>
              <option value="latest">Latest</option>
              <option value="oldest">Oldest</option>
              <option value="category">Category</option>
            </select>
          </form>
          <br />
          {order ? (
            order == "latest" ? ([
              ...[...BlogContent[2].data].map((element, i) => {
                return (
                  <FadeInRight animationTime={1 + i * 0.2}>
                    <UpdateSmall User key={i} id={element.id} />
                  </FadeInRight>
                );
              }), <FadeInRight animationTime={1 + BlogContent[2].data.length * 0.2}>
              <div className="UpdateSmall">
                <article>
                  <img
                    src={"./Content/Images/Earlier.png"}
                    alt="Project Image"
                    style={{ margin: "20px", maxWidth: "40%", maxHeight: "40%" }}
                  />
                  <div>
                    <h1>Earlier projects and beginnings: </h1>
                    <br />
                    <img
                      src={"./Content/Images/Earlier.png"}
                      alt="Project Image"
                      style={{ margin: "20px", " width": "50%" }}
                      className="MImg"
                    />
                    <p>
                      This section of the website contains older projects and how
                      i got into programming and robotics.
                    </p>
                    <label> 2016-2020 </label>
                    <br />
                    <a
                      onClick={() => {
                        navigate("/Earlier");
                      }}
                    >
                      Learn more
                    </a>
                  </div>
                </article>
              </div>
            </FadeInRight>]
            ) : order == "oldest" ? ([<FadeInRight animationTime={0.8}>
              <div className="UpdateSmall">
                <article>
                  <img
                    src={"./Content/Images/Earlier.png"}
                    alt="Project Image"
                    style={{ margin: "20px", maxWidth: "40%", maxHeight: "40%" }}
                  />
                  <div>
                    <h1>Earlier projects and beginnings: </h1>
                    <br />
                    <img
                      src={"./Content/Images/Earlier.png"}
                      alt="Project Image"
                      style={{ margin: "20px", " width": "50%" }}
                      className="MImg"
                    />
                    <p>
                      This section of the website contains older projects and how
                      i got into programming and robotics.
                    </p>
                    <label> 2016-2020 </label>
                    <br />
                    <a
                      onClick={() => {
                        navigate("/Earlier");
                      }}
                    >
                      Learn more
                    </a>
                  </div>
                </article>
              </div>
            </FadeInRight>,
              ...[...BlogContent[2].data].reverse().map((element, i) => {
                return (
                  <FadeInRight animationTime={1 + i * 0.2}>
                    <UpdateSmall User key={i} id={element.id} />
                  </FadeInRight>
                );
              })]
            ) : order == "category" ? (
              [
                <h1 id="1">Robotics</h1>,
                ...[...BlogContent[2].data].map((element, i) => {
                  if (element["category"].indexOf("1") != -1) {
                    return (
                      <FadeInRight animationTime={1 + i * 0.2}>
                        <UpdateSmall User key={i} id={element.id} />
                      </FadeInRight>
                    );
                  }
                })
                ,
                (<div className="UpdateSmall">
                  <article>
                    <img
                      src={"./Content/Images/Earlier.png"}
                      alt="Project Image"
                      style={{ margin: "20px", maxWidth: "40%", maxHeight: "40%" }}
                    />
                    <div>
                      <h1>Earlier projects and beginnings: </h1>
                      <br />
                      <img
                        src={"./Content/Images/Earlier.png"}
                        alt="Project Image"
                        style={{ margin: "20px", " width": "50%" }}
                        className="MImg"
                      />
                      <p>
                        This section of the website contains older projects and how
                        i got into programming and robotics.
                      </p>
                      <label> 2016-2020 </label>
                      <br />
                      <a
                        onClick={() => {
                          navigate("/Earlier");
                        }}
                      >
                        Learn more
                      </a>
                    </div>
                  </article>
                </div>),
                <h1 id="2">Computer Science</h1>,
                ...[...BlogContent[2].data].map((element, i) => {
                  if (element["category"].indexOf("2") != -1) {
                    return (
                      <FadeInRight animationTime={1 + i * 0.2}>
                        <UpdateSmall User key={i} id={element.id} />
                      </FadeInRight>
                    );
                  }
                }),
              ]
            ) : (
              <div></div>
            )
          ) : (
            <div></div>
          )}
          
        </section>
        <Footer />
      </div>
    );
  }
}

export default App;
